<template>
  <!--begin::Layout-->
  <div class="d-flex flex-column flex-lg-row bg-white ">
    <!--begin::Content-->
    <div class="flex-lg-row-fluid">
      <!--begin::Messenger-->
      <div class="card shadow-none" id="kt_chat_messenger">
        <!--begin::Card header-->
        <div class="card-header" id="kt_chat_messenger_header">
          <!--begin::Title-->
          <div class="card-title">
            Discussion autour du bordereau
          </div>
          <!--end::Card toolbar-->
        </div>
        <!--end::Card header-->

        <!--begin::Card body-->
        <div class="card-body" id="kt_chat_messenger_body" style="    padding: 2rem 0rem 1rem 1rem;  background: url(/media/logos/backAb.jpg) no-repeat center center fixed; background-size: cover;">
          <!--begin::Messages-->
          <div
            class="scroll-y me-n5 pe-5"
            style="height: calc(100vh - 242px)"
            ref="messagesRef"
            data-kt-element="messages"
            data-kt-scroll="true"
            data-kt-scroll-activate="{default: false, lg: true}"
            :style="`max-height: calc(100vh - 580px - ${38 * state.files.length + 'px'})`"
            data-kt-scroll-dependencies="#kt_header, #kt_toolbar, #kt_footer, #kt_chat_messenger_header, #kt_chat_messenger_footer"
            data-kt-scroll-wrappers="#kt_content, #kt_chat_messenger_body"
            data-kt-scroll-offset="0px"
          >
            <template v-for="(item, index) in state.messages" :key="index">
              <MessageIn
                ref="messagesInRef"
                v-if="item.type === 'in'"
                :name="item.name"
                :image="item.image"
                :time="item.time"
                :text="item.text"
                :key="state.messages"
                :header="item.header"
                :files="item.files"
              ></MessageIn>
              <MessageOut
                ref="messagesOutRef"
                v-if="item.type === 'out'"
                :image="item.image"
                :time="item.time"
                :text="item.text"
                :key="state.messages" 
                :header="item.header"
                :files="item.files"
              ></MessageOut>
            </template>
          </div>
          <!--end::Messages-->
        </div>
        <!--end::Card body-->

        <!--begin::Card footer-->
        <div class="card-footer pt-4 " id="kt_chat_messenger_footer">
          <!--begin::Input-->
          <input
            v-model="newMessageText"
            @keydown.enter="addNewMessage"
            class="form-control form-control-flush mb-3"
            data-kt-element="input"
            placeholder="Ecrire un nouveau message..."
          />
          <!--end::Input-->

          <template v-for="(item, index) in state.files" :key="index">
            <a @click="deleteFile(item)" class="btn btn-sm btn-white btn-active-light-danger px-4 py-2 me-4 mb-2" style="width: 100%; text-align: left; text-align: left; word-break: break-all; width: 100%; overflow: hidden; text-overflow: ellipsis; display: -webkit-box; -webkit-line-clamp: 1; -webkit-box-orient: vertical; max-height: 28px;">
              <i class="las la-trash fs-2 me-2"></i>
              Fichier : {{ item.name }}
            </a>
          </template>

          <!--begin:Toolbar-->
          <div class="d-flex flex-stack">
            <!--begin::Actions-->
            <div class="d-flex align-items-center me-2">

              <el-upload ref="uploadRef" action="#" :on-change="submitUpload" :show-file-list="false" style="width:100%;" :auto-upload="false">
                <button class="btn btn-sm btn-icon btn-active-light-primary me-1" type="button">
                  <i class="bi bi-paperclip fs-3"></i>
                </button>
              </el-upload>


              <el-dropdown @command="handleCommand">
                <span class="el-dropdown-link">
                  <button class="btn btn-sm btn-icon btn-active-light-primary me-1 fs-3" type="button">😊</button>
                </span>
                <template #dropdown>
                  <el-dropdown-menu>
                    <el-dropdown-item command="👍">👍</el-dropdown-item>
                    <el-dropdown-item command="👎">👎</el-dropdown-item>
                    <el-dropdown-item command="😄">😄</el-dropdown-item>
                    <el-dropdown-item command="😉">😉</el-dropdown-item>
                    <el-dropdown-item command="😊">😊</el-dropdown-item>
                    <el-dropdown-item command="😐">😐</el-dropdown-item>
                    <el-dropdown-item command="😕">😕</el-dropdown-item>
                    <el-dropdown-item command="😠">😠</el-dropdown-item>
                    <el-dropdown-item command="😭">😭</el-dropdown-item>

                  </el-dropdown-menu>
                </template>
              </el-dropdown>

              

            </div>
            <!--end::Actions-->

            <!--begin::Send-->
            <button
              @click="addNewMessage"
              class="btn btn-primary"
              type="button"
              data-kt-element="send"
            >
              Envoyer
            </button>
            <!--end::Send-->
          </div>
          <!--end::Toolbar-->
        </div>
        <!--end::Card footer-->
      </div>
      <!--end::Messenger-->
    </div>
    <!--end::Content-->
  </div>
  <!--end::Layout-->
</template>

<script lang="ts">
import { computed, defineComponent, onMounted, ref, reactive, onBeforeUnmount } from "vue";
import { useRoute, useRouter } from "vue-router";
import { ScrollComponent } from "@/assets/ts/components";
import contacts from "@/core/data/contacts";
import MessageIn from "@/components/messenger-parts/MessageIn.vue";
import MessageOut from "@/components/messenger-parts/MessageOut.vue";
import store from "@/store";
import mAxiosApi from "@/api";
import * as moment from "moment";


export default defineComponent({
  name: "private-chat",
  components: {
    MessageIn,
    MessageOut,

  },
  emits: ["newMessage"],
  setup(props, { emit }) {

    moment.default.locale("fr");
    const messagesRef = ref<null | HTMLElement>(null);
    const messagesInRef = ref<null | HTMLElement>(null);
    const messagesOutRef = ref<null | HTMLElement>(null);

    const handleCommand = (command: string | number ) => {
      newMessageText.value += command;
    }

    const route = useRoute();
    const router = useRouter();

    const currentBord = parseInt(localStorage.getItem("currentBord") || "0");

    const state = reactive({
      messages : [] as any,
      files: [] as any,
      loaderUpload : false,
      dernierID : 0,
    });


    const newMessageText = ref("");


    const addNewMessage = () => {
      if (!newMessageText.value && state.files.length == 0 ) {
        return;
      }

      const form = {
        dis_c_message : newMessageText.value,
        dis_knum_bordereau : parseInt(localStorage.getItem("currentBord") || "0"),
        files : state.files,
      }
      
      mAxiosApi
        .post("/addMessage", form)
        .then( async() => {
            newMessageText.value = "";
            state.files = [];
            getMessages();          
        })
    };

    const isGroupChat = computed(() => {
      return route.path.indexOf("/group-chat") !== -1;
    });

    const getMessages = async () => {
      const mMessages = await getAxios("/getBordMessage/" + currentBord);

      let mUser = "";
      let mDate = "1970-01-01 00:00:00";
      mMessages.results.forEach((el) => {

        el.files = JSON.parse(el.files);
        if (el.files.length === 0) el.files = "";

        const duration = moment.duration(moment.default(el.time, "YYYY-MM-DD hh:mm:ss").diff(moment.default(mDate, "YYYY-MM-DD hh:mm:ss")))
        if ( (duration.asMinutes() < 1) && el.name == mUser ) {
            el.header = "";
        } 
        mUser = el.name;
        mDate = el.time;
        state.dernierID = el.dis_n_seq;
      })

      state.messages = mMessages.results;
      scrollBottom();
    }

    const scrollBottom = () => {
      setTimeout(() => {
        if (messagesRef.value) {
          messagesRef.value.scrollTop = messagesRef.value.scrollHeight-100;
        }
      }, 1);
    }
    
    const submitUpload = () => {
      state.loaderUpload = true;
      const file1: any = document.querySelector('input[type=file]');
      const file = file1.files[0];

      if (file) {
          var reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = function(e) {
              if(e.target) state.files.push({ name: file.name, data: e.target.result });
          };
      }
    }

    const deleteFile = (file) => {
      for( var i = 0; i < state.files.length; i++){ 
        if ( state.files[i].name === file.name) { 
          state.files.splice(i, 1); 
        }
      }
      scrollBottom();
    }

    let refreshInterval = "" as any;

    onBeforeUnmount(() => {
      clearInterval(refreshInterval);
    });

    onMounted(async() => {
        if (!store.getters.isUserAuthenticated){
            router.push({ name: "sign-in" });
        }
        await getMessages();
        refreshInterval = setInterval(async function(){
          const mData = await getAxios("/messageDernierID/" + currentBord);
          const dis_seq = mData.results.dis_n_seq ? mData.results.dis_n_seq : 0;
          if (dis_seq != state.dernierID) {
              emit("newMessage", mData.results.dis_n_seq + "/" + state.dernierID);
              await getMessages();
          }
        },4500);

      if (!messagesRef.value) {
        return;
      }
      ScrollComponent.bootstrap();
    });

    return {
      messagesRef,
      newMessageText,
      addNewMessage,
      messagesInRef,
      messagesOutRef,
      contacts,
      isGroupChat,
      state,
      handleCommand,
      submitUpload,
      deleteFile
    };
  },
});

export async function getAxios(request: string): Promise<any> {
  const response = await mAxiosApi.get(request);
  return response.data;
}

</script>

<style scoped>
.el-dropdown-menu__item {
  font-size:1.75rem;
}
</style>